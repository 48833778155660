import { message } from 'antd'
import Icon from '@ant-design/icons'
import { SwitchLanguageWrapper, SwitchTitle } from './styled'
import { useTranslation } from 'react-i18next'
import { languageList } from '@/utils/i18n'
import { ZhSwgIcon, EnSwgIcon } from './icon'

const SwitchLanguagePage = () => {
  const { i18n, t } = useTranslation()

  const languageChange = (str: string) => i18n.changeLanguage(str)

  const languageChangeSwitch = (str: any) => {
    console.log('str', str)
    languageChange(str)
    message.info({
      content: t('app.switch.language.tips', { msg: languageList.find((l) => l.locale === str)?.value }),
      className: 'message-global',
    })
  }

  return (
    <SwitchLanguageWrapper onClick={() => languageChangeSwitch(languageList.find((l) => l.locale !== i18n.language)?.locale)}>
      <Icon component={i18n.language !== 'zh-TW' ? ZhSwgIcon : EnSwgIcon} />
      <SwitchTitle>{languageList.find((l) => l.locale === i18n.language)?.value}</SwitchTitle>
    </SwitchLanguageWrapper>
  )
}

export default SwitchLanguagePage
