const home = {
  'home.title': 'GridMindDAO',
  'home.sub.title': 'Community Whitelist Recruitment',
  'home.card.btn': 'Registration',
  'home.communites.title1': 'Junior Community',
  'home.communites.title2': 'Intermediate Community',
  'home.card.tips.success': 'Registration successful',
  'home.u.title': 'Staked',
  'home.g.title': 'Get',
  'home.account.balance.titles': 'Account balance : {{balance}} {{msg}}',
}

export default home
