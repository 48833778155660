export const EnSwgIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9775 17.0079H19.6941L20.7817 23.0724C21.5035 22.812 22.1561 22.433 22.7329 21.9287C22.1429 21.2069 21.6552 20.406 21.286 19.5491L22.433 19.4008C22.7494 20.05 23.1119 20.6235 23.5206 21.1179C24.3677 20.0896 25.0203 18.7185 25.4751 17.0046L19.9775 17.0079ZM24.3083 21.932C24.9609 22.4989 25.7058 22.9109 26.543 23.168L27.067 23.3295L26.7605 24.4468L26.2365 24.2853C25.2312 23.9788 24.3051 23.4514 23.5206 22.7494C22.7856 23.4086 21.9254 23.9128 20.9894 24.226L21.7244 28.3096H17.3573L16.7706 30.9792H30.4552C31.0979 30.9792 31.6187 30.4585 31.6187 29.8158V10.0239C31.6187 9.38123 31.0979 8.86047 30.4552 8.86047H18.2307L19.1404 13.9329L19.1041 13.9197L19.2096 14.4767L19.2261 14.4042L19.4832 15.8478H22.3044V14.6843H24.4863V15.8478H28.125V17.0112H26.6089C26.1013 19.0547 25.3367 20.6993 24.3083 21.932ZM16.1642 28.303H5.5448C4.2594 28.303 3.2179 27.2615 3.2179 25.9761V6.1842C3.2179 4.8988 4.2594 3.8573 5.5448 3.8573H17.1859L17.9176 7.69702H30.4519C31.7373 7.69702 32.7788 8.73853 32.7788 10.0239V29.8158C32.7788 31.1012 31.7373 32.1427 30.4519 32.1427H15.3204L16.1642 28.303ZM10.6633 19.3381V18.1154H8.36279V16.3125H10.5018V15.0996H8.36279V13.5637H10.6633V12.3508H7.02466V19.3348H10.6633V19.3381ZM16.2861 19.3381V15.8544C16.2861 15.2216 16.1411 14.7371 15.8511 14.3976C15.561 14.0614 15.1326 13.8933 14.5723 13.8933C14.2427 13.8933 13.9493 13.9592 13.6923 14.0911C13.4352 14.2229 13.2242 14.424 13.0858 14.6777H13.0133L12.8353 13.9955H11.8268V19.3381H13.1451V16.8234C13.1451 16.1938 13.2275 15.7423 13.3923 15.4688C13.5538 15.1952 13.8241 15.0601 14.1932 15.0601C14.4635 15.0601 14.6613 15.1556 14.7832 15.3501C14.9084 15.5446 14.9711 15.8313 14.9711 16.2169V19.3381H16.2861Z"
      fill="white"
    />
  </svg>
)

export const ZhSwgIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_18_167)">
      <path
        d="M6.521 10.686C6.86552 11.7046 7.43473 12.5883 8.22863 13.3673C8.9027 12.6333 9.412 11.7345 9.74154 10.686H6.521Z"
        fill="white"
      />
      <path
        d="M27.5219 4.6643H14.9393L14.2203 0.814621H2.53647C1.24826 0.814621 0.199707 1.86317 0.199707 3.15139V23.0139C0.199707 24.3021 1.24826 25.3507 2.53647 25.3507H13.1867L12.3329 29.2004H27.5219C28.8101 29.2004 29.8587 28.1518 29.8587 26.8636V7.00106C29.8587 5.71284 28.8101 4.6643 27.5219 4.6643ZM11.6589 16.468C10.2808 15.9587 9.14234 15.3146 8.2286 14.5656C7.26993 15.4194 6.07158 16.0485 4.67851 16.438L4.19917 15.6441C5.56229 15.2846 6.70071 14.7453 7.59947 13.9814C6.67075 13.0377 6.02665 11.9442 5.66714 10.7009H4.36395V9.80219H7.76424C7.55453 9.41273 7.28491 9.03824 6.97034 8.67874L7.8691 8.3492C8.18366 8.75364 8.46827 9.23297 8.72292 9.78721H11.9734V10.686H10.6702C10.2508 11.9742 9.65163 13.0527 8.85773 13.9215C9.74151 14.6255 10.85 15.2097 12.1532 15.689L11.6589 16.468ZM28.6903 26.8486C28.6903 27.4927 28.166 28.017 27.5219 28.017H13.8009L14.3851 25.3357H18.774L16.2725 11.39L16.2575 11.4649L16.1526 10.9107L16.1826 10.9256L15.2689 5.83268H27.5369C28.181 5.83268 28.7053 6.35695 28.7053 7.00106V26.8486H28.6903Z"
        fill="white"
      />
      <path
        d="M19.1934 15.8239H22.414V14.97H19.1934V13.2624H22.6237V12.4086H18.1898V18.5501H22.7585V17.6963H19.1934V15.8239ZM25.9191 13.9814C25.6495 13.9814 25.4098 14.0263 25.1851 14.1462C24.9754 14.251 24.7657 14.4158 24.6009 14.6255V14.1012H23.6273V18.5501H24.6009V15.8688C24.6309 15.5093 24.7507 15.2397 24.9605 15.0449C25.1402 14.8802 25.3499 14.7903 25.5896 14.7903C26.2636 14.7903 26.5932 15.1498 26.5932 15.8838V18.5351H27.5668V15.7939C27.5968 14.5806 27.0276 13.9814 25.9191 13.9814Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_18_167">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
