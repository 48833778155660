import styled from 'styled-components'

export const SwitchLanguageWrapper = styled.div`
  cursor: pointer;
  display: flex;
  margin-right: 1.88rem;
  display: flex;
  align-items: center;
`

export const SwitchTitle = styled.div`
  font-size: 0.88rem;
  font-weight: 700;
  margin-left: 0.63rem;
  color: ${({ theme }) => theme.white};
  font-family: 'NotoSansHans-Regular';
`
