import { useMemo, useState } from 'react'
import { message, Image } from 'antd'
import { useBoolean } from 'ahooks'
import {
  HomeCard,
  HomeCardInfo,
  CardInfoTitle,
  CardInfoAmount,
  CardInfoBtn,
  HomeSubtitle,
  HomeTitle,
  HomeWrapper,
  HomeLoading,
  HomeContent,
  HomeCardTwo,
  CardInfos,
} from './styled'
import { useWeb3React } from '@web3-react/core'
import { metaMask } from '@/connectors/metaMask'
import { DEFAULT_CHAINID } from '@/contracts/constant'
import { getAddChainParameters } from '@/contracts/chains'
import { useTranslation } from 'react-i18next'
import ConnectWallet from '@/components/ConnectWallet'
import useDataHooks from '@/hooks/useDataHooks'
import { useHomeHooks, CommunitesLsitInit } from '@/hooks/useHomeHooks'
import { amountVerificationNative, amountVerificationNativeTwo } from '@/common/verification'

const Home = () => {
  const { web3, constant, fromWeiPowBanlance, toWeiPowBanlance, Community_ADDRESS } = useDataHooks().data
  const { isActive, isActivating, account } = useWeb3React()
  // @ts-ignore
  const { ethereum } = window
  const { t } = useTranslation()

  const [isRefresh, setIsRefresh] = useBoolean(false)
  const [cardLoading, setCardLoading] = useBoolean(false)
  const [currentType, setCurrentType] = useState<'primary' | 'intermediate' | undefined>(undefined)

  const { accountIsJoinedCommunity, loading, usersInfo, communityAmount } = useHomeHooks({ account, isRefresh })

  useMemo(() => {
    if (!isActive && ethereum && ethereum.isMetaMask) void metaMask.activate(getAddChainParameters(DEFAULT_CHAINID))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInit = () => {
    setCurrentType(undefined)
    void setCardLoading.setFalse()
  }

  const handleCardBtn = async (type: 'primary' | 'intermediate') => {
    setCurrentType(type)
    if (!account) return
    void setCardLoading.setTrue()
    try {
      const { isTrue } = await amountVerificationNative({ web3, account })
      const tokenInfo = await amountVerificationNativeTwo({ web3, account, constant: constant.ContractToken })
      if (!tokenInfo.isTrue || !isTrue) {
        handleInit()
        return
      }
      let amountAccountUsdt = fromWeiPowBanlance({ balance: tokenInfo.balance, decimals: '6' })
      let minAmountType = communityAmount.find((c) => c.communityType === type)?.amount || '0'
      if (Number(amountAccountUsdt) < Number(minAmountType)) {
        handleInit()
        message.info({
          content: t('home.account.balance.titles', { msg: 'USDT', balance: Number(amountAccountUsdt) }),
          className: 'message-global',
        })
        return
      }
      handleAuthorizedAmount({ communityType: type === 'primary' ? 1 : 2, amount: minAmountType })
      console.log(tokenInfo)
    } catch (error) {
      console.log('error', error)
      handleInit()
    }
  }

  const handleAuthorizedAmount = async ({ communityType, amount }: { communityType: 1 | 2; amount: string }) => {
    console.log('amount', amount)
    let amounts = toWeiPowBanlance({ decimals: '6', balance: amount })
    try {
      let AuthorizedAmount = await constant.ContractToken.methods.allowance(account, Community_ADDRESS).call()
      console.log('AuthorizedAmount', AuthorizedAmount, Number(AuthorizedAmount) < Number(amounts || '0'), amounts)
      if (Number(AuthorizedAmount) < Number(amounts || '0')) {
        constant.ContractToken.methods
          .approve(Community_ADDRESS, amounts)
          .send({
            from: account,
          })
          .on('transactionHash', function (hash: any) {
            console.log(hash)
          })
          .on('receipt', async (receipt: any) => {
            handleCardImplement({ communityType })
          })
          .on('error', function (error: any, receipt: any) {
            message.error({
              content: error.message,
              className: 'message-global',
            })
            handleInit()
          })
      } else handleCardImplement({ communityType })
    } catch (error) {
      console.log('error', error)
      handleInit()
    }
  }

  const handleCardImplement = async ({ communityType }: { communityType: 1 | 2 }) => {
    try {
      constant.ContractCommunity.methods
        .joinCommunity(communityType)
        .send({
          from: account,
        })
        .on('transactionHash', function (hash: any) {
          console.log(hash)
        })
        .on('receipt', async (receipt: any) => {
          message.success({
            content: t('home.card.tips.success'),
            className: 'message-global',
          })
          handleInit()
          void setIsRefresh.toggle()
        })
        .on('error', function (error: any, receipt: any) {
          message.error({
            content: error.message,
            className: 'message-global',
          })
          handleInit()
        })
    } catch (error) {
      console.log('error', error)
      handleInit()
    }
  }

  return (
    <HomeWrapper>
      {isActivating && <HomeLoading size="large" tip={t('app.loading')} spinning={true} />}
      {isActive && loading && <HomeLoading size="large" tip={t('app.loading')} spinning={true} />}
      {!isActivating && !loading && (
        <HomeContent>
          <HomeTitle>{t('home.title')}</HomeTitle>
          <HomeSubtitle>{t('home.sub.title')}</HomeSubtitle>
          {!accountIsJoinedCommunity && (
            <HomeCard>
              {CommunitesLsitInit.map((c) => (
                <HomeCardInfo key={c.key}>
                  <Image src={c.image} width="100%" preview={false} />
                  <CardInfoTitle>{t(c.name)}</CardInfoTitle>
                  <CardInfoAmount>{communityAmount.find((i) => i.communityType === c.key)?.amountT}U</CardInfoAmount>
                  {!isActive ? (
                    <ConnectWallet isPosition="Home" />
                  ) : (
                    <CardInfoBtn
                      disabled={currentType !== c.key && cardLoading}
                      loading={currentType === c.key && cardLoading}
                      onClick={() => handleCardBtn(c.key as any)}
                    >
                      {t('home.card.btn')}
                    </CardInfoBtn>
                  )}
                </HomeCardInfo>
              ))}
            </HomeCard>
          )}
          {accountIsJoinedCommunity && usersInfo && isActive && (
            <HomeCardTwo>
              {CommunitesLsitInit.filter((item) => item.key === usersInfo.communityType).map((c) => (
                <HomeCardInfo key={c.key}>
                  <Image src={c.image} width="100%" preview={false} />
                  <CardInfoTitle>{t(c.name)}</CardInfoTitle>
                  <CardInfos>
                    <span>{t('home.u.title')}</span>
                    <div className="amount">
                      {communityAmount.find((i) => i.communityType === c.key)?.amountT}
                      <span>U</span>
                    </div>
                  </CardInfos>
                  <CardInfos>
                    <span>{t('home.g.title')}</span>
                    <div className="amount">
                      ???
                      <span>GMD</span>
                    </div>
                  </CardInfos>
                </HomeCardInfo>
              ))}
            </HomeCardTwo>
          )}
        </HomeContent>
      )}
    </HomeWrapper>
  )
}

export default Home
