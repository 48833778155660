import { memo } from 'react'
import { LayoutWrapper, LayoutContent, LayoutTopBar } from './styled'
import { Outlet } from 'react-router-dom'
import TopBar from '@/components/TopBar'
import { useScrollTransparent } from '@/hooks/useScrollTransparent'

export default memo(() => {
  const transparent = useScrollTransparent()
  return (
    <LayoutWrapper>
      <LayoutTopBar style={{ background: `rgba(193, 114, 58,${transparent})` }}>
        <TopBar />
      </LayoutTopBar>
      <LayoutContent>
        <Outlet />
      </LayoutContent>
    </LayoutWrapper>
  )
})
