import { memo } from 'react'
import { TopBarWrapper } from './styled'
import { Row, Col } from 'antd'
import SwitchLanguage from '@/components/SwitchLanguage'
import ConnectWallet from '@/components/ConnectWallet'

export default memo(function TopBarPage() {
  return (
    <TopBarWrapper>
      <Row className="topbar-ant-row">
        <Col span={24} className="topbar-right">
          <ConnectWallet />
          <SwitchLanguage />
        </Col>
      </Row>
    </TopBarWrapper>
  )
})
