import { REACT_APP_ENV } from '@/contracts/chains'
import Token from '@/contracts/abis/Token.json'
import Community from '@/contracts/abis/Community.json'

interface ConstantChainIdType {
  Token_ADDRESS: string
  Community_ADDRESS: string
  apiUrl: string
  apiKey: string
}

interface UseConstantType {
  [chainId: number]: ConstantChainIdType
}

export const DEFAULT_CHAINID_ENV: { [env: string]: number } = {
  dev: 97,
  uat: 97,
  prd: 56,
}

export const useConstant: { [env: string]: UseConstantType } = {
  dev: {
    97: {
      Token_ADDRESS: '0xe8122ED514ebf1b65f6D0dF2FD77800134a95077',
      Community_ADDRESS: '0xcbC529C8F361d6C229DEDDf2c8843A403783BeC6',
      apiKey: 'https://api-testnet.bscscan.com/api',
      apiUrl: '366TDMB1M11NCFABM78212QFUM81INYK1C',
    },
  },
  uat: {
    97: {
      Token_ADDRESS: '',
      Community_ADDRESS: '',
      apiKey: 'https://api-testnet.bscscan.com/api',
      apiUrl: '366TDMB1M11NCFABM78212QFUM81INYK1C',
    },
  },
  prd: {
    56: {
      Token_ADDRESS: '0x55d398326f99059ff775485246999027b3197955',
      Community_ADDRESS: '0x7C46D2ff7D62539C3EE6F1Cf28BF81e124262349',
      apiKey: 'https://api.bscscan.com/api',
      apiUrl: '366TDMB1M11NCFABM78212QFUM81INYK1C',
    },
  },
}

export const Token_ABI: any = Token
export const Community_ABI: any = Community

export const DEFAULT_CHAINID: number = DEFAULT_CHAINID_ENV[REACT_APP_ENV]

export const USECONSTANT: UseConstantType = useConstant[REACT_APP_ENV]

export const getActiveChainId = (arr: string[], network: number) => {
  if (network === null) return false
  return arr.some((item) => Number(item) === Number(network))
}
