const home = {
  'home.title': 'GridMindDAO',
  'home.sub.title': '社區白名單招募',
  'home.card.btn': '報名',
  'home.communites.title1': '初級社區',
  'home.communites.title2': '中級社區',
  'home.card.tips.success': '報名成功',
  'home.u.title': '已質押',
  'home.g.title': '獲得',
  'home.account.balance.titles': '賬戶餘額：{{balance}} {{msg}}',
}

export default home
