import { useEffect, useState } from 'react'
import CommunitesIcon1 from '@/assets/1.png'
import CommunitesIcon2 from '@/assets/2.png'
import useDataHooks from '@/hooks/useDataHooks'

type Types = {
  account: string | undefined
  isRefresh: boolean
}

type CommunitesLsitInitTypes = {
  key?: string
  image: string
  name: string
  amount: string
  uGmd?: string
}

type UserInfoTypes = {
  communityType: 'primary' | 'intermediate'
}

type CommunityAmountTypes = {
  communityType: 'primary' | 'intermediate'
  amount: undefined | string
  amountT: undefined | string
}

export const CommunitesLsitInit: CommunitesLsitInitTypes[] = [
  { key: 'primary', image: CommunitesIcon1, name: 'home.communites.title1', amount: '10000', uGmd: '0.00' },
  { key: 'intermediate', image: CommunitesIcon2, name: 'home.communites.title2', amount: '50000', uGmd: '0.00' },
]

const CommunityAmountInit: CommunityAmountTypes[] = [
  { communityType: 'primary', amount: undefined, amountT: undefined },
  { communityType: 'intermediate', amount: undefined, amountT: undefined },
]

export function useHomeHooks({ account, isRefresh }: Types) {
  const { constant, fromWeiPowBanlance } = useDataHooks().data

  const [loading, setLoading] = useState<boolean>(false)
  const [usersInfo, setUserInfo] = useState<UserInfoTypes | undefined>(undefined)
  const [accountIsJoinedCommunity, setAccountIsJoinedCommunity] = useState<boolean>(false)
  const [communityAmount, setCommunityAmount] = useState<CommunityAmountTypes[]>(CommunityAmountInit)

  useEffect(() => {
    setAccountIsJoinedCommunity(false)
    setCommunityAmount(CommunityAmountInit)
    getAmount()
    if (account) getIsJoinedCommunity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh, account])

   const getAmount = async () => {
    setLoading(true)
    try {
      let promise = await Promise.all([
        constant.ContractCommunity.methods.getCommunityPrice(1).call(),
        constant.ContractCommunity.methods.getCommunityPrice(2).call(),
      ]).then((res) => {
        return {
          communityPrice1: res[0],
          communityPrice2: res[1],
        }
      })
      console.log('promise', promise)
      let communityPrice1 = fromWeiPowBanlance({ decimals: '6', balance: promise.communityPrice1 })
      let communityPrice2 = fromWeiPowBanlance({ decimals: '6', balance: promise.communityPrice2 })
      setCommunityAmount([
        {
          communityType: 'primary',
          amount: Number(communityPrice1).toString(),
          amountT: Number(communityPrice1).toLocaleString('en-US', { maximumFractionDigits: 6, style: 'decimal' }),
        },
        {
          communityType: 'intermediate',
          amount: Number(communityPrice2).toString(),
          amountT: Number(communityPrice2).toLocaleString('en-US', { maximumFractionDigits: 6, style: 'decimal' }),
        },
      ])
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
   }

  const getIsJoinedCommunity = async () => {
    setLoading(true)
    try {
      let promise = await Promise.all([
        constant.ContractCommunity.methods.isJoinedCommunity(account).call(),
        constant.ContractCommunity.methods._users(account).call()
      ]).then((res) => {
        return {
          isJoinedCommunity: res[0],
          _users: res[1]
        }
      })
      console.log('promise', promise)
      let isJoinedCommunity = promise.isJoinedCommunity
      let _users = promise._users
      if (_users.communityType === '1' || _users.communityType === '2')
        setUserInfo({
          communityType: _users.communityType === '1' ? 'primary' : 'intermediate',
        })
      setAccountIsJoinedCommunity(!isJoinedCommunity)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return { accountIsJoinedCommunity, loading, usersInfo, communityAmount }
}
