import Web3 from 'web3'
import { Token_ABI, Community_ABI, USECONSTANT } from '@/contracts/constant'
import { REACT_APP_ENV } from '@/contracts/chains'
import BigNumber from 'bignumber.js'

export interface ConstantTypes {
  ContractToken: any
  ContractCommunity: any
}

export interface ConstantInitTypes {
  web3: Web3
  Token_ADDRESS: string
  Community_ADDRESS: string
  constant: ConstantTypes
  toWeiPowBanlance: ({ decimals, balance }: { decimals: string; balance: string }) => string
  fromWeiPowBanlance: ({ decimals, balance }: { decimals: string; balance: string }) => string
  apiUrl: string
  apiKey: string
}

export class ConstantInit {
  web3: Web3
  Token_ADDRESS: string
  Community_ADDRESS: string
  constant: ConstantTypes
  apiUrl: string
  apiKey: string

  constructor(provider: any, chainId: number) {
    const { Token_ADDRESS, apiKey, apiUrl, Community_ADDRESS } = USECONSTANT[chainId]

    this.web3 = new Web3(provider)
    this.Token_ADDRESS = Token_ADDRESS
    this.Community_ADDRESS = Community_ADDRESS
    this.apiKey = apiKey
    this.apiUrl = apiUrl
    this.constant = {
      ContractToken: new this.web3.eth.Contract(Token_ABI, Token_ADDRESS),
      ContractCommunity: new this.web3.eth.Contract(Community_ABI, Community_ADDRESS),
    }

    console.log('REACT_APP_ENV', REACT_APP_ENV)
  }

  fromWeiPowBanlance = ({ decimals, balance }: { decimals: string; balance: string }) => {
    let wei = new BigNumber(10).pow(decimals)
    let balances = new BigNumber(balance).div(wei).toFixed(6)
    return balances
  }

  toWeiPowBanlance = ({ decimals, balance }: { decimals: string; balance: string }) => {
    let wei = new BigNumber(10).pow(decimals)
    let balances = new BigNumber(balance).times(wei).toFixed(0)
    return balances
  }
}
