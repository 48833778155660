import styled, { css } from 'styled-components'
import { Button, Spin } from 'antd'
import { h5LayoutAdaptation, flatLayoutAdaptation, webLayoutAdaptation, webLayoutAdaptationMax, HomeBtnCss } from '@/common/styled'

export const HomeWrapper = styled.div`
  ${h5LayoutAdaptation}
  width: 100%;
  min-height: calc(100vh - 5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${(props) =>
    props.theme.mediaWidth.md(
      () => css`
        ${flatLayoutAdaptation}
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.mxl(
      () => css`
        ${webLayoutAdaptation}
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.maxl(
      () => css`
        ${webLayoutAdaptationMax}
      `,
    )}
`

export const HomeLoading = styled(Spin)`
  color: ${({ theme }) => theme.white};
  .ant-spin-dot-item {
    background-color: ${({ theme }) => theme.white};
  }
`

export const HomeContent = styled.div`
  position: relative;
  display: flex;
  padding-bottom: 1.88rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) =>
    theme.mediaWidth.md(
      () => css`
        top: -5.9375rem;
        padding-bottom: 0;
      `,
    )}
`



export const HomeTitle = styled.div`
  font-family: 'NotoSansHans-Regular';
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.75rem;
  margin-top: 2.25rem;
  color: ${({ theme }) => theme.white};
  ${({ theme }) =>
    theme.mediaWidth.md(
      () => css`
        margin-top: 0;
        font-size: 6rem;
        line-height: 12rem;
      `,
    )}
`

export const HomeSubtitle = styled.div`
  margin-bottom: 1.88rem;
  margin-top: 1em;
  text-align: center;
  font-family: 'NotoSansHans-Regular';
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 1.88rem;
  color: ${({ theme }) => theme.white};
  ${({ theme }) =>
    theme.mediaWidth.md(
      () => css`
        margin-top: 0;
        margin-bottom: 5rem;
        font-size: 2.25rem;
        line-height: 1.875rem;
        margin-bottom: 1em;
      `,
    )}
`

export const HomeCardTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.88rem;
  ${({ theme }) =>
    theme.mediaWidth.md(
      () => css`
        gap: 5rem;
      `,
    )}
`

export const HomeCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.88rem;
  ${({ theme }) =>
    theme.mediaWidth.md(
      () => css`
        grid-template-columns: 1fr 1fr;
        gap: 5rem;
      `,
    )}
`

export const HomeCardInfo = styled.div`
  background: ${({ theme }) => theme.white};
  border-radius: 0.5rem;
  padding: 0.94rem;
  width: calc(18.25rem - 0.94rem - 0.94rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .ant-image-img {
    border-radius: 0.5rem;
  }
`

export const CardInfoTitle = styled.div`
  font-family: 'NotoSansHans-Regular';
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 0.94rem;
  color: ${({ theme }) => theme.black};
  width: 100%;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const CardInfoAmount = styled.div`
  font-family: 'NotoSansHans-Regular';
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.88rem;
  margin: 0.31rem 0 0.94rem;
  color: ${({ theme }) => theme.themeColor};
`

export const CardInfoBtn = styled(Button)`
  ${HomeBtnCss}
`

export const CardInfos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0.63rem 0;
  span {
    font-family: 'NotoSansHans-Regular';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #999999;
  }
  .amount {
    font-family: 'NotoSansHans-Regular';
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.88rem;
    color: ${({ theme }) => theme.themeColor};
    span {
      margin-left: 0.32rem;
    }
  }
`
